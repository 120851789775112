import { version1PathPreferences, type PathPreferenceSpec } from "@gadgetinc/conventions";
import path from "path";
import { ReservedTopLevelNames, ValidGraphQLIdentifier } from "../EditValidations";
import { getNamespace } from "../FilesystemIntrospector";
import { schemaMetadataFileName } from "../database/schemaMetadataFileName";
import { getPotentialNamespaceProblems } from "../problems/finders/NamespaceProblems";

export const jsOrTsFileSet = new Set([".js", ".ts", ".mjs", ".mts", ".cjs", ".cts"]);

export const isJsOrTsFile = (filePath: string) => {
  return jsOrTsFileSet.has(path.extname(filePath));
};

export const isExecutableJsOrTsFile = (filePath: string) => {
  return isJsOrTsFile(filePath) && !filePath.endsWith(".d.ts");
};

export const globalActionsDir = "api/actions/";
export const modelsDir = "api/models/";
const schemaFile = `/${schemaMetadataFileName}`;
const numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const schemaFileDirParts = (path: string): string[] | null => {
  if (!path.startsWith(modelsDir) || !path.endsWith(schemaFile)) {
    return null;
  }

  const dirNames = path.slice(modelsDir.length, -schemaFile.length);
  if (dirNames == "") return null;
  return dirNames.split("/");
};

export const fileIsNamespacedModelSchemaMetadata = (file: { path: string }) => {
  const parts = schemaFileDirParts(file.path);
  return Boolean(parts);
};

export const fileIsNonNamespacedModelSchemaMetadata = (file: { path: string }) => {
  const parts = schemaFileDirParts(file.path);
  if (!parts) return false;
  return parts.length == 1 && !numbers.includes(parts[0][0]);
};

export const fileIsGlobalAction = (file: { path: string }, canHaveNamespacedModels: boolean) => {
  if (!file.path.startsWith(version1PathPreferences.globalActionsFolderPath)) {
    return false;
  }

  const paths = file.path.split("/");

  if (!isExecutableJsOrTsFile(file.path) || (!canHaveNamespacedModels && paths.length !== 3)) {
    return false;
  }

  // Possibly `globalActions/{action}.{valid_extension}` path structure
  const namespaceProblems = getPotentialNamespaceProblems({ supportNamespace: canHaveNamespacedModels, namespace: paths.slice(2, -1) });
  if (namespaceProblems.length) {
    return; // Cannot be a GlobalAction with invalid namespace segments
  }

  const actionApiIdentifier = path.basename(file.path, path.extname(file.path));

  const apiIdIsValidGQL = ValidGraphQLIdentifier.test(actionApiIdentifier);
  const apiIdIsReservedName = ReservedTopLevelNames.includes(actionApiIdentifier);

  return apiIdIsValidGQL && !apiIdIsReservedName;
};

export const fileIsGlobalView = (
  file: { path: string },
  canHaveNamespacedModels: boolean,
  basePath: string = version1PathPreferences.computedViewsPath
) => {
  if (!file.path.startsWith(basePath)) {
    return false;
  }

  const paths = file.path.split("/");

  if (path.extname(file.path) != ".gelly" || (!canHaveNamespacedModels && paths.length !== 3)) {
    return false;
  }

  const namespaceProblems = getPotentialNamespaceProblems({ supportNamespace: canHaveNamespacedModels, namespace: paths.slice(2, -1) });
  if (namespaceProblems.length) {
    return false; // Cannot be a global view with invalid namespace segments
  }

  const viewApiIdentifier = path.basename(file.path, path.extname(file.path));

  const apiIdIsValidGQL = ValidGraphQLIdentifier.test(viewApiIdentifier);
  const apiIdIsReservedName = ReservedTopLevelNames.includes(viewApiIdentifier);

  return apiIdIsValidGQL && !apiIdIsReservedName;
};

/**
 * Returns true if the given file is a valid model action file relative to the given schema file path
 * Validates that the file name is valid
 **/
export const fileIsModelAction = (filePath: string, schemaFilePath: string) => {
  const modelFolder = path.dirname(schemaFilePath);
  if (path.dirname(filePath) !== `${modelFolder}/actions`) return false;
  const apiIdentifier = path.basename(filePath, path.extname(filePath));
  return ValidGraphQLIdentifier.test(apiIdentifier) && isExecutableJsOrTsFile(path.basename(filePath));
};

export const fileIsModelView = (filePath: string, schemaFilePath: string) => {
  const modelFolder = path.dirname(schemaFilePath);
  if (path.dirname(filePath) !== `${modelFolder}/views`) return false;
  const apiIdentifier = path.basename(filePath, path.extname(filePath));
  return ValidGraphQLIdentifier.test(apiIdentifier) && path.extname(filePath) == ".gelly";
};

/**
 * Returns true if the given file path looks like it should be a model action.
 * Doesn't check if the action API identifier is valid or if the model actually exists
 **/
export const filePathLooksLikeModelAction = (filePath: string, pathPreferences: PathPreferenceSpec) => {
  if (!filePath.startsWith(pathPreferences.modelsFolderPath)) return false;
  const segments = filePath.split("/");
  const actionsFolderIndex = segments.indexOf("actions");
  // if the file isn't in an actions folder, or isn't in an actions folder within a model folder, it's not a model action
  if (actionsFolderIndex == -1 || actionsFolderIndex == 0) return false;
  return isExecutableJsOrTsFile(path.basename(filePath));
};

export const fileIsBackendRoute = (filePath: string, pathPreferences: PathPreferenceSpec) => {
  return pathPreferences.backendRouteFileRegex.test(filePath);
};

export const fileIsStructuredFrontendRoute = (filePath: string, pathPreferences: PathPreferenceSpec) => {
  return filePath.startsWith(pathPreferences.frontendRoutesFolder);
};

export const fileIsBootPlugin = (filePath: string, pathPreferences: PathPreferenceSpec) => {
  return filePath.startsWith(pathPreferences.bootPluginsPath);
};

export const schemaFilePathToModelIdentifier = (schemaFilePath: string) => {
  const parts = schemaFileDirParts(schemaFilePath);
  if (!parts) return;

  return {
    apiIdentifier: parts[parts.length - 1],
    namespace: parts.length > 1 ? getNamespace(parts.slice(0, -1).join("/")) : [],
  };
};

export const getFolderPathEndingWithSlash = (folderPath: string) => {
  return folderPath.endsWith("/") ? folderPath : `${folderPath}/`;
};

export const convertTsToJsFileName = (filePath: string) => {
  return filePath.replace(/\.tsx?$|\.ts$|\.mts$|\.cts$/g, (match) => {
    switch (match) {
      case ".tsx":
        return ".jsx";
      case ".mts":
        return ".mjs";
      case ".cts":
        return ".cjs";
      default:
        return ".js";
    }
  });
};
