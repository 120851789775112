import { useStyletron } from "baseui";
import type { Theme, Typography } from "baseui/theme";
import type { ReactHTML } from "react";
import * as React from "react";
import { Waypoint } from "react-waypoint";
import type { StyleObject } from "styletron-react";
import { parameterize } from "superflected";
import { Anchor } from "web/src/components/edit/shared/Anchor";
import { useHover } from "web/src/lib/hooks";
import { getChildrenText } from "web/src/lib/utils";
import { useLocation } from "wouter";

export const Heading = ({
  element,
  fontType,
  children,
  $style,
  id,
}: {
  element: keyof ReactHTML;
  fontType: keyof Theme["typography"];
  children: React.ReactNode;
  $style?: StyleObject;
  id?: string;
}) => {
  const [isHovered, hoverProps] = useHover();
  const text = getChildrenText(children);
  const slug = id ?? parameterize(text);
  const [css, $theme] = useStyletron();
  const [location, setLocation] = useLocation();
  const currentPage = location.replace(/#.*$/, "");
  const childCount = React.Children.count(children);

  const itemId = `${currentPage}#${slug}`;

  return React.createElement(
    element,
    {
      ...hoverProps,
      id: slug,
      "data-docscategory": true,
      className:
        css({
          ...$theme.typography[fontType],
          color: $theme.colors.contentPrimary,
          display: "flex",
          alignItems: "center",
          gap: $theme.sizing.scale400,
          marginTop: $theme.sizing.scale900,
          marginBottom: $theme.sizing.scale700,
          flexWrap: "wrap",
          scrollMarginTop: $theme.sizing.scale1600,
          ...$style,
        }) + " docs-heading",
    },
    <React.Fragment>
      {React.Children.map(children, (child, idx) => {
        // For the headings that wrap on smaller screens we need to make sure that that anchor does not wrap on its own to a next line so we add it to the last child with a non-break space
        if (idx === childCount - 1) {
          return (
            <span>
              {child}&nbsp;
              {element !== "h5" && element !== "h6" && <Anchor isVisible={isHovered} slug={slug} element={element} />}
            </span>
          );
        }

        return child;
      })}
      {typeof window != "undefined" && element !== "h5" && element !== "h6" && (
        <Waypoint
          scrollableAncestor={window}
          onEnter={({ event }) => {
            if ((event?.target as Document)?.location.pathname === currentPage) {
              setLocation(itemId, { replace: true });
            }
          }}
          bottomOffset={"90%"}
          fireOnRapidScroll={false}
        />
      )}
    </React.Fragment>
  );
};

export const headingComponent = (element: keyof ReactHTML, fontType: keyof Typography, $style?: StyleObject) => {
  const HeadingComponent = (props: { children: React.ReactNode; id?: string }) => {
    return <Heading element={element} fontType={fontType} {...props} $style={$style} />;
  };
  return HeadingComponent;
};

export const H1 = headingComponent("h1", "HeadingLarge");
export const H2 = headingComponent("h2", "HeadingMedium");
export const H3 = headingComponent("h3", "HeadingSmall");
export const H4 = headingComponent("h4", "HeadingXSmall");
export const H5 = headingComponent("h5", "HeadingXSmall");
export const H6 = headingComponent("h6", "HeadingXSmall");
