import { Button, Row } from "@gadgetinc/widgets";
import { CheckmarkIcon } from "@gadgetinc/widgets/src/icons/CheckmarkIcon";
import { ChevronDownIcon } from "@gadgetinc/widgets/src/icons/ChevronDownIcon";
import { NestedMenus, StatefulMenu } from "baseui/menu";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import React, { useContext, useMemo, useState } from "react";
import { LanguageIcon } from "web/src/components/markdown/LanguageIndicator";
import { DocsContext } from "../DocsContext";

const languages = ["TypeScript", "JavaScript"];

export const DocsLanguageSelector = (props: { useFullFrameworkLabel?: boolean }) => {
  const { setShowTsLanguage, showTsLanguage } = useContext(DocsContext);
  const currentLanguage = showTsLanguage ? "TypeScript" : "JavaScript";

  const dropdownOptions = useMemo(() => {
    return languages.map((language) => ({
      id: language,
      label: <LanguageLabel language={language} includeCheckmark={language === currentLanguage} />,
    }));
  }, [currentLanguage]);

  const [isOpen, setIsOpen] = useState(false);

  return (
    <StatefulPopover
      placement={PLACEMENT.bottomLeft}
      stateReducer={(stateChangeType, nextState) => {
        setIsOpen(nextState.isOpen);
        return nextState;
      }}
      content={({ close }) => (
        <NestedMenus>
          <StatefulMenu
            items={dropdownOptions}
            overrides={{ List: { style: { maxHeight: "300px", overflowY: "auto", width: "262px" } } }}
            onItemSelect={({ item }) => {
              setShowTsLanguage(item.id === "TypeScript");
              close();
            }}
          />
        </NestedMenus>
      )}
    >
      <Button
        data-testid="docs-language-selector"
        size="compact"
        kind="secondary"
        $pressed={isOpen}
        endEnhancer={() => <ChevronDownIcon />}
      >
        <LanguageLabel language={currentLanguage} />
      </Button>
    </StatefulPopover>
  );
};

const LanguageLabel = (props: { language: string; includeCheckmark?: boolean }) => {
  const { language, includeCheckmark } = props;
  return (
    <Row $gap="4px">
      <LanguageIcon language={language} />
      {language}
      {includeCheckmark && <CheckmarkIcon style={{ marginLeft: "auto" }} />}
    </Row>
  );
};
