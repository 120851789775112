import { expandBorderRadii, expandMargin, expandPadding, paddingHorizontal, Row } from "@gadgetinc/widgets";
import { SidebarNavBreakpoint } from "@gadgetinc/widgets/src/DocsTheme";
import { LongerArrowIcon } from "@gadgetinc/widgets/src/icons/LongerArrowIcon";
import { OpenSideNav } from "@gadgetinc/widgets/src/icons/OpenSideNav";
import { useStyletron } from "baseui";
import type { ButtonOverrides } from "baseui/button";
import type { DrawerOverrides } from "baseui/drawer";
import { ANCHOR, Drawer } from "baseui/drawer";
import { find } from "lodash";
import React, { Fragment, useContext, useState } from "react";
import { DocsButton } from "../../components/DocsButton";
import { getAreaIcon } from "../../icons";
import { DocsContext } from "../DocsContext";
import { DocsDropDownSelectors } from "../DocsDropDownSelectors";
import { DocsNavLink, DocsNavLinkHeader } from "./DocsNavLink";
import type { GadgetItemProps } from "./navigation-types";
import { useDocsLocation } from "./useDocsLocation";

enum DocsCategory {
  Root = "root",
  Guides = "guides",
  API = "api",
  Reference = "reference",
  Assistant = "assistant",
}

const docsCategoriesWithVersionSelector = [DocsCategory.Guides];
const docsCategoriesWithEnvironmentSelector = [DocsCategory.API];

const getCurrentDocsCategory = (currentDrawerNavAreaItemId?: string) => {
  if (!currentDrawerNavAreaItemId) {
    return DocsCategory.Root;
  }
  if (currentDrawerNavAreaItemId.startsWith("/guides")) {
    return DocsCategory.Guides;
  }
  if (currentDrawerNavAreaItemId.startsWith("/api")) {
    return DocsCategory.API;
  }
  if (currentDrawerNavAreaItemId.startsWith("/reference")) {
    return DocsCategory.Reference;
  }
  if (currentDrawerNavAreaItemId.startsWith("/assistant")) {
    return DocsCategory.Assistant;
  }
  return DocsCategory.Root;
};

export const DocsNavDrawer = () => {
  const [css, $theme] = useStyletron();
  const [isOpen, setIsOpen] = useState(false);

  const [location, setLocation] = useDocsLocation();
  const { topLevelItems } = useContext(DocsContext);

  /* Nav works a little differently than in the sidebar; here, you can expand top-level menus that you are not currently navigated to (e.g. you can browse /guides headings if your current route is /api/your-app).

  (In the sidebar navigation, you can't expand top-level nav items that you aren't currently navigated to. (e.g. you can't look at /guides from /gelly))

  Default to current location
  */
  const [currentDrawerNavArea, setCurrentDrawerNavArea] = useState<GadgetItemProps | null>(
    () => topLevelItems.find((item) => location?.startsWith(item.itemId)) || null
  );

  const currentDocsCategory = getCurrentDocsCategory(currentDrawerNavArea?.itemId);
  const showVersionSelector = docsCategoriesWithVersionSelector.includes(currentDocsCategory);
  const showEnvironmentSelector = docsCategoriesWithEnvironmentSelector.includes(currentDocsCategory);

  const subNavContents = (
    <>
      {!!currentDrawerNavArea && (
        <DocsNavLink
          selected={false}
          icon={getAreaIcon(currentDrawerNavArea.itemId)}
          $style={{ paddingLeft: "40px" } as any}
          anchorProps={{
            onClick: (event) => {
              event.preventDefault();
              setCurrentDrawerNavArea(null);
            },
          }}
        >
          <span data-testid="drawerDrillUp">{currentDrawerNavArea.title}</span>
          <div
            className={css({
              position: "absolute",
              zIndex: 100,
              top: "6px",
              marginLeft: `-${$theme.sizing.scale1400}`,
            })}
          >
            <LongerArrowIcon direction={"left"} />
          </div>
        </DocsNavLink>
      )}
      {(currentDrawerNavArea?.subNav || []).map((item: GadgetItemProps, i) => {
        const selected = !find(topLevelItems, { itemId: item.itemId }) && location.startsWith(item.itemId);

        return item.onlyHeader ? (
          <DocsNavLinkHeader key={`${item.itemId}-${i}`} $style={{ paddingLeft: "40px" } as any}>
            {item.title}
          </DocsNavLinkHeader>
        ) : (
          <DocsNavLink
            key={`${item.itemId}-${i}`}
            selected={selected}
            anchorProps={{
              href: item.itemId,
              onClick: item.itemId.startsWith("http")
                ? undefined
                : (event) => {
                    event.preventDefault();
                    if (!selected) {
                      setLocation(item.itemId);
                    } else {
                      // We're already at the location, so we just close the drawers
                      setIsOpen(false);
                    }
                  },
              target: item.itemId.startsWith("http") ? "_blank" : undefined,
            }}
            $style={{ paddingLeft: "40px" } as any}
          >
            <span data-testid="drawerPageLink">{item.title}</span>
          </DocsNavLink>
        );
      })}
    </>
  );

  const topNavContents = (
    <>
      {topLevelItems.map((item: any) => {
        return (
          <DocsNavLink
            key={item.itemId}
            selected={false}
            icon={getAreaIcon(item.itemId)}
            anchorProps={{
              onClick: (event) => {
                event.preventDefault();
                setCurrentDrawerNavArea(item);
              },
            }}
            $style={{ paddingRight: $theme.sizing.scale200 }}
            endEnhancer={<LongerArrowIcon direction="right" />}
          >
            <span data-testid="drawerNavItem">{item.title}</span>
          </DocsNavLink>
        );
      })}
    </>
  );

  return (
    <Fragment>
      <DocsButton
        kind="minimal"
        onClick={() => setIsOpen(true)}
        startEnhancer={() => <OpenSideNav />}
        overrides={hamburgerButtonOverrides(true)}
      />

      <Drawer
        renderAll
        onClose={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        anchor={ANCHOR.left}
        overrides={drawerOverrides()}
      >
        <Row $gap="10px" $style={{ marginLeft: "10px", position: "absolute", top: "10px" }}>
          <DocsDropDownSelectors forceShowVersionSelector={showVersionSelector} forceShowEnvironmentSelector={showEnvironmentSelector} />
        </Row>
        <>{currentDrawerNavArea ? subNavContents : topNavContents}</>
      </Drawer>
    </Fragment>
  );
};

const drawerOverrides = (): DrawerOverrides => ({
  Root: {
    style: {
      [`@media screen and ${SidebarNavBreakpoint}`]: {
        display: "none",
      },
    },
  },
  DrawerContainer: {
    props: { "data-baseweb-name": "DrawerContainer" },
    style: ({ $theme }) => ({
      ...expandBorderRadii("0px"),
      paddingBottom: 0,
      borderRightWidth: "3px",
      borderRightColor: $theme.borders.border400.borderColor,
      borderRightStyle: "solid",
    }),
  },
  DrawerBody: {
    props: { "data-baseweb-name": "DrawerBody", "data-testid": `docsDrawer` },
    style: ({ $theme }) => ({
      ...expandMargin(0),
      ...paddingHorizontal($theme.sizing.scale200),
      paddingBottom: $theme.sizing.scale1200,
      paddingTop: $theme.sizing.scale1600,
      scrollbarWidth: "none",
      overflow: "scroll",
      position: "relative",
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "::-webkit-scrollbar": {
        display: "none",
      },
    }),
  },
  Close: {
    props: { "data-testid": `docsDrawerCloseButton` },
  },
});

const hamburgerButtonOverrides = (showNavDrawerButton: boolean): ButtonOverrides => ({
  BaseButton: {
    props: { "data-testid": "docsDrawerHamburger" },
    style: !showNavDrawerButton
      ? { display: "none" }
      : {
          backgroundColor: "transparent",
          ...expandPadding(0),
          color: "#000000",
          ":focus": {
            outlineWidth: "0px",
          },
          ":hover": {
            color: "#000000",
          },
        },
  },
});
