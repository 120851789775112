import { SidebarNavBreakpoint, WideSidebarNavBreakpoint } from "@gadgetinc/widgets/src/DocsTheme";
import { styled, useStyletron } from "baseui";
import { StyledLink } from "baseui/link";
import { ParagraphSmall } from "baseui/typography";
import { initialize } from "esbuild-wasm";
import esbuildWasm from "esbuild-wasm/esbuild.wasm?url";
import { useNavigationDetails } from "fastify-renderer/client/react";
import { observer } from "mobx-react-lite";
import type { ReactNode } from "react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import type { LegacyEnvironmentName } from "state-trees/src/Environment";
import type { GadgetFlags } from "state-trees/src/GadgetFlags";
import { CurrentFlags, FlagsProvider, useFlags } from "web/src/lib/flags";
import { useSetSearchInsightsToken } from "../DocsAlgoliaSearchIndex";
import type { DocsUser, IDocsParams } from "./DocsContext";
import { DocsContext, currentDocsArea } from "./DocsContext";
import { DocsNavContextProvider } from "./DocsNavContext";
import type { FullMetaBlob, ShortMetaBlob } from "./FullMetaBlob";
import { DocsLeftNav, LargeSidebarWidth, SmallSidebarWidth } from "./nav/DocsLeftNav";
import { DocsNavItems } from "./nav/DocsNavContents";
import { DocsTopBar } from "./nav/DocsTopBar";
import { currentDocsVersion } from "./nav/DocsVersionSelector";
import { useDocsLocation } from "./nav/useDocsLocation";
import type { TemplateSchema } from "./template/TemplateSchema";

declare global {
  interface Window {
    GadgetDocsConfig: {
      release: string;
      servicesRootURL: string;
    };
  }
}

export type DocsArea = "api" | "guides" | "reference" | "assistant" | "notFound";

let esbuildInitializePromise: Promise<void> | undefined;

let isTsLanguage: boolean;
/**
 * Since a different instance of the context is created for each page, we need to track the state outside of the context to avoid resetting the state on each page change.
 */
const getIsTsLanguage = (languagePreference: "typescript" | "javascript") => {
  if (isTsLanguage === undefined) {
    isTsLanguage = languagePreference === "typescript";
  }
  return isTsLanguage;
};

export const FlagAwareDocsContext = (props: { children: ReactNode; contextProps: IDocsParams }) => {
  const flags = useFlags();
  const [location] = useDocsLocation();
  const topLevelItems = useMemo(() => {
    return DocsNavItems(location, flags, props.contextProps.currentApp);
  }, [location, props.contextProps.currentApp, flags]);
  const docsArea = currentDocsArea(location);
  const [showTsLanguage, setShowTsLanguage] = useState(getIsTsLanguage(props.contextProps.currentApp.languagePreference));
  const [initializedTranspiler, setInitializedTranspiler] = useState(false);
  useEffect(() => {
    async function initializeWasmModules() {
      if (!esbuildInitializePromise) {
        // the `initialize` function can only be called once or it will throw an error
        esbuildInitializePromise = initialize({
          wasmURL: esbuildWasm,
        });
      }

      await esbuildInitializePromise;
      setInitializedTranspiler(true);
    }

    void initializeWasmModules();
  }, []);

  return (
    <DocsContext.Provider
      value={{
        ...props.contextProps,
        topLevelItems,
        docsArea,
        showTsLanguage,
        setShowTsLanguage: (setState) => {
          const newValue = !showTsLanguage;
          isTsLanguage = newValue;
          setShowTsLanguage(setState);
        },
        initializedTranspiler,
      }}
    >
      {props.children}
    </DocsContext.Provider>
  );
};

const DocsMainContainer = styled<{ $hasLeftSidebar: boolean }, "div">("div", ({ $hasLeftSidebar }) => ({
  flexGrow: 1,
  flexShrink: 0,
  display: "flex",
  flexDirection: "column",
  [`@media screen and ${SidebarNavBreakpoint}`]: $hasLeftSidebar
    ? {
        marginLeft: SmallSidebarWidth,
        maxWidth: `calc(100vw - ${SmallSidebarWidth})`,
      }
    : undefined,
  [`@media screen and ${WideSidebarNavBreakpoint}`]: $hasLeftSidebar
    ? {
        marginLeft: LargeSidebarWidth,
        maxWidth: `calc(100vw - ${SmallSidebarWidth})`,
      }
    : undefined,
}));

export interface DocsInterfaceProps {
  availableApps: ShortMetaBlob[];
  currentApp: FullMetaBlob;
  title: string;
  pageTitle: string;
  showNavDrawerButton: boolean;
  templateSchema?: TemplateSchema;
  currentUser?: DocsUser;
  headerHeroContent?: ReactNode;
  children: ReactNode;
  showLeftSideNav?: boolean;
  params?: {
    environmentName?: LegacyEnvironmentName;
  };
  GadgetFlags: GadgetFlags;
}

/** Used by all pages, both markdown-y ones and customized ones, to show the chrome around the content like the header and footer */
export const DocsInterface = observer((props: DocsInterfaceProps) => {
  const [css, _$theme] = useStyletron();
  const [location] = useDocsLocation();
  const mainContainer = useRef<HTMLDivElement>(null);

  const urlBase = (path: string) => path.split("#")[0];

  const [isNavigating, navigationDestination] = useNavigationDetails();
  const isOffpageNavigating = isNavigating && urlBase(navigationDestination) != urlBase(location);
  const showLeftSideNav = props.showLeftSideNav ?? true;

  useSetSearchInsightsToken(props.currentUser?.id);

  return (
    <FlagsProvider flags={CurrentFlags}>
      <DocsNavContextProvider isNavigating={isOffpageNavigating} currentApp={props.currentApp}>
        <FlagAwareDocsContext contextProps={props}>
          <DocsTopBar mainContainerRef={mainContainer} />
          <div className={css({ position: "relative", flexGrow: 1, display: "flex", flexDirection: "column" })}>
            {showLeftSideNav && <DocsLeftNav />}

            <DocsMainContainer data-testid="main-container" ref={mainContainer} $hasLeftSidebar={showLeftSideNav}>
              {props.children}
              <div className={css({ flexGrow: 1 })} />
              <DocsFooter />
            </DocsMainContainer>
          </div>
        </FlagAwareDocsContext>
      </DocsNavContextProvider>
    </FlagsProvider>
  );
});

function DocsFooter() {
  const [css, $theme] = useStyletron();
  const [location] = useDocsLocation();
  const { currentApp, currentUser } = useContext(DocsContext);
  const version = currentDocsVersion(location, currentApp);

  return (
    <footer
      className={css({
        display: "flex",
        justifyContent: "center",
        width: "100%",
        paddingTop: $theme.sizing.scale400,
        paddingBottom: $theme.sizing.scale400,
      })}
    >
      <ParagraphSmall color="primary400">
        Powered by <StyledLink href="https://gadget.dev">Gadget</StyledLink>. {currentUser?.isStaff && <>Docs version: {version.id}</>}
      </ParagraphSmall>
    </footer>
  );
}
