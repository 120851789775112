import { Button, Card, CardSection, Input, Row, paddingUtil } from "@gadgetinc/widgets";
import { CheckmarkIcon } from "@gadgetinc/widgets/src/icons/CheckmarkIcon";
import { ChevronDownIcon } from "@gadgetinc/widgets/src/icons/ChevronDownIcon";
import { SearchIcon } from "@gadgetinc/widgets/src/icons/SearchIcon";
import { useStyletron } from "baseui";
import { StatefulPopover } from "baseui/popover";
import { ParagraphSmall } from "baseui/typography";
import { filter } from "fuzzaldrin-plus";
import React, { useContext, useMemo, useState } from "react";
import type { EnvironmentSlug } from "state-trees/src/Environment";
import { ProductionEnvironmentName, ProductionEnvironmentSlug } from "state-trees/src/Environment";
import type { StyleObject } from "styletron-react";
import useFetch from "use-http";
import { safelyRunAsyncEventHandler } from "web/src/lib/utils";
import { EnvironmentNamePill } from "../../components/EnvironmentNameTag";
import { Divider } from "../Divider";
import { DocsContext } from "../DocsContext";
import { useDocsLocation } from "./useDocsLocation";

export const DocsAPIEnvironmentSelector = () => {
  const { loading: _loading, post: setCurrentApplication } = useFetch("/api/set-current-app");
  const [location, setLocation] = useDocsLocation();
  const { currentApp } = useContext(DocsContext);

  const environmentOptions = useMemo(() => {
    return currentApp.environmentsMetaData.map((env) => ({ id: env.slug, label: env.slug }));
  }, [currentApp.environmentsMetaData]);

  return (
    <EnvironmentSelector
      options={environmentOptions}
      value={currentApp.environment.slug}
      onChange={(item) => {
        safelyRunAsyncEventHandler(async () => {
          await setCurrentApplication({ slug: currentApp.slug, environment: item.id });

          if (location.includes("/api")) {
            setLocation(`/api/${currentApp.slug}/${item.id}/`);
          }

          window.location.reload();
        });
      }}
    />
  );
};

const EnvironmentSelector = (props: {
  options: { id: EnvironmentSlug; label: string }[];
  value: EnvironmentSlug;
  onChange: (item: { id: EnvironmentSlug }) => void;
  disabled?: boolean;
  buttonStyle?: StyleObject;
}) => {
  const [_css, $theme] = useStyletron();

  let filteredOptions = props.options.filter((option) => option.id.toLowerCase() !== "production");

  const [searchQuery, setSearchQuery] = useState("");

  if (searchQuery.trim().length > 0) {
    filteredOptions = filter(filteredOptions, searchQuery, { key: "label" });
  }

  const [isOpen, setIsOpen] = useState(false);

  return (
    <StatefulPopover
      placement={"bottomLeft"}
      stateReducer={(stateChangeType, nextState) => {
        setIsOpen(nextState.isOpen);
        return nextState;
      }}
      content={() => (
        <Card $style={{ width: "328px" }} tabIndex={-1} data-testid="docs-app-list">
          <CardSection $style={{ gap: $theme.sizing.scale0, ...paddingUtil("8px", "8px", 0, "8px") }}>
            <Input
              type="search"
              startEnhancer={<SearchIcon />}
              placeholder="search"
              value={searchQuery}
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              disabled={false}
            />
          </CardSection>
          <CardSection $padding="small" $style={{ gap: $theme.sizing.scale0 }}>
            <Row
              $align="space-between"
              $style={{
                alignItems: "center",
                padding: $theme.sizing.scale300,
                borderRadius: $theme.borders.radius300,
                [":hover"]: { cursor: "pointer", backgroundColor: $theme.colors.primary50 },
              }}
              onClick={() => props.onChange({ id: ProductionEnvironmentSlug })}
            >
              <EnvironmentNamePill environment={ProductionEnvironmentName} />
              {props.value === "production" && <CheckmarkIcon />}
            </Row>
            <Divider />
            <Row>
              <ParagraphSmall>Development environments</ParagraphSmall>
            </Row>
            {filteredOptions.map((option) => {
              return (
                <Row
                  key={option.id}
                  $align="space-between"
                  $style={{
                    padding: $theme.sizing.scale300,
                    alignItems: "center",
                    borderRadius: $theme.borders.radius300,
                    [":hover"]: { cursor: "pointer", backgroundColor: $theme.colors.primary50 },
                  }}
                  onClick={() => props.onChange({ id: option.id })}
                >
                  <EnvironmentNamePill environment={option.id} />
                  {option.id === props.value && <CheckmarkIcon />}
                </Row>
              );
            })}
          </CardSection>
        </Card>
      )}
    >
      <Button $pressed={isOpen} kind="secondary" endEnhancer={() => <ChevronDownIcon />} $style={{ maxWidth: "180px" }}>
        <div style={{ maxWidth: "140px", display: "flex", alignItems: "center" }}>
          <EnvironmentNamePill environment={props.value} />
        </div>
      </Button>
    </StatefulPopover>
  );
};
